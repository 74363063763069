<template>
	<div class="ele-body">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="短信推送" name="first" class="tab_ads">
				<el-form ref="form" label-width="120px" class="d-flexspabet">
					<el-card shadow="never" class="w-40 mt-20" style="height:160px">
						<div class="d-flex smsInner">
							<div class="blueLine">|</div>
							<div>可用短信条数（条）</div>
						</div>
						<div class="d-flexspabet" style="padding:20px 0 10px 30px">
							<div style="font-size:25px">{{editFirst.remaining_nums}}</div>
							<div>
								<el-button type="primary" @click="handlePurchase" v-if="permission.includes('sys:marketing_check_ads:buy')">购买短信套餐</el-button>
								<el-button type="primary" @click="dialogVisibleSending=true" v-if="permission.includes('sys:marketing_check_ads:view')">查看发送记录</el-button>
								<el-button type="primary" @click="handleSending" v-if="permission.includes('sys:marketing_check_ads:use')">使用短信模板</el-button>
							</div>
						</div>
					</el-card>
					<el-card shadow="never" class="w-40" style="margin-top:20px;height:160px">
						<div class="d-flex smsInner">
							<div class="blueLine">|</div>
							<div>发送数据（条）</div>
						</div>
						<div class="d-flexspabet" style="padding:20px 0 10px 30px;width:70%">
							<div style="font-size:25px;line-height:25px">
								<div>{{editFirst.the_month}}</div>
								<div class="colorOrange">本月发送条数</div>
							</div>
							<div style="font-size:25px;line-height:25px">
								<div>{{editFirst.the_week}}</div>
								<div class="colorOrange">本周发送条数</div>
							</div>
							<div style="font-size:25px;line-height:25px">
								<div>{{editFirst.the_today}}</div>
								<div class="colorOrange">本日发送条数</div>
							</div>
						</div>
					</el-card>
				</el-form>
				<el-card shadow="never" class="mt-20 " style="width:100%">
					<div class="d-flex" style="border-bottom: 1px solid #ccc;">
						<div class="blueLine smsInner">|</div>
						<div>发送短信</div>
					</div>
					<div class="d-flexspabet boxType" sytle="padding-top:20px;">
                        <div style="width: 100%;height: 20px;"></div>
						<div style="flex:2">
							<el-form class="driverForm" label-width='180px' >
								<!-- :disabled="disabled" -->
								<el-form-item label="短信内容：" prop="description">
									<el-input @input="handleInput" :rows="10" v-model="editForm.sendContent"
										placeholder="请输入短信内容" type="textarea" clearable class="w-800" style="width: 95%;" />
								</el-form-item>
								<el-form-item label="" prop="oversion" label-width='180px'>
									<div class="orange">
										已输入{{editForm.sendNum}}个字符，按{{editForm.sendPrice}}条计费（超出70个字符，按2条计费）</div>
								</el-form-item>
								<el-form-item label="请选择地区：" class=" d-flex w-800 area">
									<el-select v-model="editForm.pid" @change="handleChangeProv(editForm.pid)"
										class="selectStyle input163 mr-10 mb-25" placeholder="请选择省"
										:disabled="editForm.id?true:false">
										<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
											:label="option.name"></el-option>
									</el-select>
									<el-select v-model="editForm.cid" @change="handleChangeCity(editForm.cid)"
										class="selectStyle input163 mr-10" placeholder="请选择市"
										:disabled="editForm.id?true:false">
										<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
											:label="option.name"></el-option>
									</el-select>
									<el-select v-model="editForm.aid" @change="handleDistrictArr()"
										class="selectStyle input163 mr-10" placeholder="请选择区/县"
										:disabled="editForm.id?true:false">
										<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
											:label="option.name"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="发送对象：" prop="type">
									<el-radio-group v-model="editForm.type" style="margin-right: 20px"
										@change="hangleTypt">
										<el-radio :label="2">司机</el-radio>
										<el-radio :label="1">用户</el-radio>
										<el-radio :label="3">商家</el-radio>
										<el-radio :label="4">跑腿</el-radio>
									</el-radio-group>
									<el-select v-model="editForm.active" v-if="editForm.type==1" class="width:200px;">
										<el-option value="1" label="全部"></el-option>
										<el-option value="1" label="已激活"></el-option>
										<el-option value="2" label="未激活"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="预计发送时间：" prop="send_time">
									<el-date-picker v-model="editForm.send_time" type="datetime" placeholder="选择日期">
									</el-date-picker>
									(若为空，表示审核通过后立即发送)
								</el-form-item>
								<el-form-item label="当前短信需要发送条数：" prop="oversion">
									<div>{{editFirst.nums}}条</div>
								</el-form-item>
								<div style="float:right;margin-right: 40px;" class="mt-20">
									<el-form-item>
										<el-button type="primary" @click="save" v-if="permission.includes('sys:marketing_check_ads:submit')">确认提交</el-button>
									</el-form-item>
								</div>
							</el-form>

						</div>
						<div style="border-left:1px solid rgb(240,240,240);padding-left:40px;flex:1">
							<div style="color:#45B99E;font-size: 18px;font-weight: 700;margin-bottom:20px">注意:</div>
							<div class="contentLine">
								1.短信发送格式：【签名】+内容+退订回T，例如：【可蚁点】您好，欢迎您使用可蚁点！可进入可蚁点官方网站了解更多内容。https://livingtrip.uyl.cn/ ，退订回T。
							</div>
							<div class="contentLine">
								2.短信内容1条为70字。超出70个字，按照2条计费。
							</div>
							<div class="contentLine">
								3.禁止发送任何违法违规内容。
							</div>



						</div>
					</div>

				</el-card>
				<!--购买短信套餐-->
				<el-dialog v-dialogDrag title="购买短信套餐" :visible.sync="dialogVisiblePlans" custom-class="ele-dialog-form"
					:lock-scroll="false" class="plans" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="true">
					<el-card shadow="never">
						<div class="plansBox">
							<div class="plansItem">
								<div class="plansInner " v-for="option in editFive"
									:class="{'greenborder':option.greenborder}"
									@click="handleChange(option.id,option.price)" :key="option.id">
									<div class="plansTitle">{{option.title}}</div>
									<div class="plansMoney">￥{{option.price}}</div>
									<div class="plansCluse">包含：{{option.num}}条</div>
									<div class="plansNum">单条：{{option.one_price}}元/条</div>
								</div>

							</div>
							<div class="plansDeduct">支付方式： <span>账户余额扣除</span></div>
							<div class="plansDeduct">支付金额（元）： <span>￥{{old_price}}</span></div>
						</div>

					</el-card>
					<div slot="footer">
						<!-- <el-button @click="dialogVisiblePlans=false">取消</el-button> -->
						<el-button type="primary" @click="purchaseSave">确认购买</el-button>
						<a href="javascript:location.reload()" class="closeBtn">关闭</a>
					</div>
				</el-dialog>
				<!--查看发送记录-->
				<el-dialog v-dialogDrag title="查看发送记录" :visible.sync="dialogVisibleSending" custom-class="ele-dialog-form "
					:lock-scroll="false">
					<el-card shadow="never">
						<el-form class="ele-form-search d-flex f-end" @keyup.enter.native="$refs.table.reload()"
							@submit.native.prevent>
							<el-form-item label="状态:" label-width="62px" class="w-200">
								<el-select v-model="table.where.aid">
									<el-option value="1" label="待审核"></el-option>
									<el-option value="2" label="成功"></el-option>
									<el-option value="3" label="已驳回"></el-option>
								</el-select>
							</el-form-item>


							<el-form-item>
								<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
									class="ele-btn-icon ml-20">搜索</el-button>
								<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
								<el-button icon="el-icon-download" type="success" @click="exportExcel"
									v-if="permission.includes('sys:order:export')">导出</el-button>
							</el-form-item>
						</el-form>

						<!-- 数据表格 -->
						<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
							highlight-current-row :stripe=true>
							<template slot-scope="{index}">
								<el-table-column type="index" :index="index" label="编号" width="60" align="center"
									show-overflow-tooltip />
								<el-table-column prop="name" label="管理员名称" show-overflow-tooltip min-width="120px" />
								<el-table-column prop="phone" label="状态" show-overflow-tooltip min-width="120px" />
								<el-table-column prop="sex" label="发送对象" show-overflow-tooltip min-width="120px" />
								<el-table-column prop="home" label="短信条数" show-overflow-tooltip min-width="120px" />
								<el-table-column label="提交时间" show-overflow-tooltip min-width="120px">
									<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
								</el-table-column>
								<el-table-column label="审核时间" show-overflow-tooltip min-width="120px">
									<template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
								</el-table-column>
								<el-table-column label="发送时间" show-overflow-tooltip min-width="120px">
									<template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
								</el-table-column>
								<el-table-column label="操作" align="center" :resizable="false" fixed="right">
									<template slot-scope="{row}">
										<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary"
											:underline="false" v-if="permission.includes('sys:user:view')">查看</el-link>
									</template>
								</el-table-column>
							</template>
						</ele-data-table>
					</el-card>
				</el-dialog>
				<!--使用短信模板-->
				<el-dialog v-dialogDrag title="使用短信模板" :visible.sync="dialogVisibleTemplates" custom-class="ele-dialog-form"
					:lock-scroll="false" class="templates">
					<el-card shadow="never">
						<div class="plansBox">
							<div class="plansItem">
								<div class="plansInner1" v-for="option in editFive" :key="option.id">
									<div class="plansCon">
										<div class="lifive">{{option.title}}</div>
										<div class="plansContent">{{option.content}} </div>
										<div class="plansMiaoshu">{{option.num}} 个字符（不含签名），按{{option.onde_price}} 条计费
										</div>
									</div>
									<el-button type="primary" class="ele-btn-icon " size="small"
										@click="handleSend(option.content)">立即使用</el-button>

								</div>
							</div>
						</div>
					</el-card>
				</el-dialog>

			</el-tab-pane>
			<el-tab-pane label="审核列表" name="second">
				<el-card shadow="never">
					<!-- 数据表格 -->
					<ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="real_name" label="管理员名称" show-overflow-tooltip min-width="140" />
							<el-table-column prop="tiao" label="短信条数" show-overflow-tooltip min-width="120" />
							<!--              <el-table-column prop="type" label="发送对象"  show-overflow-tooltip min-width="120"/>-->

							<el-table-column label="发送对象">
								<template slot-scope="scope">
									<span>{{scope.row.limit_type==1?'司机':'用户'}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="create_time" label="提交时间" show-overflow-tooltip min-width="120" />
							<el-table-column prop="content" label="发送内容" show-overflow-tooltip min-width="120" />
							<el-table-column prop="send_time" label="发送时间" show-overflow-tooltip min-width="120" />
							<el-table-column v-if="examine==0" label="操作" width="200px" align="center" :resizable="false" fixed="right">
								<template>
									<el-link icon="el-icon-success" type="primary" :underline="false"
										@click="check_success(row)" v-if="permission.includes('sys:marketing_check_ads:success')">通过</el-link>
									<el-link icon="el-icon-error" type="primary" :underline="false"
										@click="check_error(row)" v-if="permission.includes('sys:marketing_check_ads:error')">驳回</el-link>
								</template>
							</el-table-column>
							<el-table-column v-if="examine!=0" label="操作" width="200px" align="center"
								:resizable="false" fixed="right">
								<template slot-scope="scope">
									{{scope.row.examine==1?'已通过':'已驳回'}}
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="推送记录" name="three">
				<el-card shadow="never">
					<el-form class="ele-form-search d-flex f-end" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table2.reload()" @submit.native.prevent>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table2.where.pid" @change="handleChangeProv2(table2.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table2.where.cid" @change="handleChangeCity2(table2.where.cid)"
								placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table2.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="状态：" prop="limit_type" label-width="62px" class="w-200">
							<el-select v-model="table2.where.limit_type" placeholder="请选择" class="ele-fluid" clearable>
								<!-- <el-option label="全部" value="3" /> -->
								<el-option label="未发送" value="0" />
								<el-option label="已发送" value="1" />
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="$refs.table2.reload()" icon="el-icon-search"
								class="ele-btn-icon search-margin-left">搜索</el-button>
							<el-button @click="(table2.where={})&&$refs.table2.reload()">重置</el-button>
						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table2" :config="table2" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="real_name" label="管理员名称" show-overflow-tooltip min-width="140" />
							<!--              <el-table-column prop="status" label="状态"  show-overflow-tooltip min-width="120"/>-->
							<el-table-column label="状态">
								<template slot-scope="scope">
									<span>{{scope.row.status==1?'已发送':(scope.row.status==0?'未发送':'')}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="set_area" label="发送地区" show-overflow-tooltip min-width="120" />
							<!--              <el-table-column prop="type" label="发送对象"  show-overflow-tooltip min-width="120"/>-->

							<el-table-column label="发送对象">
								<template slot-scope="scope">
									<span>{{scope.row.limit_type==1?'司机':(scope.row.limit_type==2?'用户':'')}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="tiao" label="短信条数" show-overflow-tooltip min-width="120" />
							<el-table-column prop="create_time" label="提交时间" show-overflow-tooltip min-width="120" />
							<el-table-column prop="examine_time" label="审核时间" show-overflow-tooltip min-width="120" />
							<el-table-column prop="send_time" label="发送 时间" show-overflow-tooltip min-width="120" />
							<el-table-column label="操作" width="120px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link icon="el-icon-view" type="primary" :underline="false"
										@click="viewFive(row)">查看</el-link>
								</template>

							</el-table-column>
						</template>
					</ele-data-table>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="回执记录 " name="fourth">
				<el-card shadow="never">
					<el-form class="ele-form-search d-flex f-end" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table3.reload()" @submit.native.prevent>
						<el-form-item label="发送类型：" prop="platform" label-width="90px" class="w-200">
							<el-select v-model="table3.where.type" placeholder="请选择" clearable class="ele-fluid">
								<el-option label="司机" value="1" />
								<el-option label="用户" value="2" />
								<el-option label="商家" value="3" />
								<el-option label="跑腿" value="4" />
							</el-select>
						</el-form-item>
						<el-form-item label="回执结果：" prop="platform" label-width="90px" class="w-200">
							<el-select v-model="table3.where.code" placeholder="请选择" clearable class="ele-fluid">
								<el-option label="成功" value="2" />
								<el-option label="失败" value="1" />
							</el-select>
						</el-form-item>
						<el-form-item label="是否驳回短信：" prop="platform" label-width="120px" class="w-250">
							<el-select v-model="table3.where.is_reject" placeholder="请选择" clearable class="ele-fluid">
								<el-option label="未操作" value="2" />
								<el-option label="已驳回短信" value="1" />
							</el-select>
						</el-form-item>
						<el-form-item label="开始时间：" label-width="90px">
							<el-date-picker v-model="table3.where.start_time" type="date" placeholder="选择日期" clearable>
							</el-date-picker>
						</el-form-item>
						<el-form-item label="结束时间：" label-width="90px">
							<el-date-picker v-model="table3.where.end_time" type="date" placeholder="选择日期" clearable>
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="$refs.table3.reload()" icon="el-icon-search"
								class="ele-btn-icon search-margin-left">搜索</el-button>
							<el-button @click="(table3.where={})&&$refs.table3.reload()">重置</el-button>
						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table3" :config="table3" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="real_name" label="运营商" show-overflow-tooltip min-width="140" />
							<el-table-column prop="type" label="发送类型" show-overflow-tooltip min-width="120" />
							<el-table-column prop="content" label="短信模板" show-overflow-tooltip min-width="120" />
							<el-table-column prop="tiao" label="退回条数" show-overflow-tooltip min-width="120" />
							<el-table-column prop="code" label="状态值" show-overflow-tooltip min-width="120" />
							<el-table-column prop="msg" label="回执结果" show-overflow-tooltip min-width="120" />
							<el-table-column prop="mobilephone" label="发送手机号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="task_id" label="流水号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="report_time" label="回执时间" show-overflow-tooltip min-width="120" />
							<el-table-column prop="is_reject" label="是否驳回短信次数(次)" show-overflow-tooltip min-width="120" />
							<el-table-column prop="reject_time" label="驳回时间" show-overflow-tooltip min-width="120" />
							<el-table-column prop="create_time" label="添加时间" show-overflow-tooltip min-width="120" />
						</template>
					</ele-data-table>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="套餐设置" name="five">
				<el-card shadow="never">
					<el-form ref="form" label-width="120px">
						<div class="innerBox" v-for="option in editFive" :key="option.id">
							<el-form-item>
								<span slot="label">套餐{{option.id==1?'一':option.id==2?'二':option.id==3?'三':'四'}}：</span>
							</el-form-item>
							<div class="d-flex">
								<el-form-item label="套餐名称：">
									<el-input v-model="option.title" class="inputWidth"></el-input>
								</el-form-item>
								<el-form-item label="套餐价格(￥)：">
									<el-input v-model="option.price" class="inputWidth"></el-input>
								</el-form-item>
								<el-form-item label="包含内容(条)：">
									<el-input v-model="option.num" class="inputWidth"></el-input>
								</el-form-item>
								<el-form-item label="短信单价(元/条)：" label-width="135px" class="w-400">
									<el-input v-model="option.one_price" class="inputWidth" style="width:200px">
									</el-input>
								</el-form-item>
							</div>
						</div>
						<div style="float:right;" class="mt-20">
							<el-form-item>
								<el-button type="primary" @click="saveFive" v-if="permission.includes('sys:marketing_check_ads:tc_submit')">确认提交</el-button>
							</el-form-item>
						</div>
					</el-form>

				</el-card>
			</el-tab-pane>
			<el-tab-pane label="模板设置" name="six">
				<el-card shadow="never">
					<el-form ref="form" label-width="120px">
						<div class="innerBox">
							<div class="d-flex" style="flex-wrap: wrap">
								<div class="innerItem" v-for="(option,index) in editSix" :key="option.id">
									<el-form-item>
										<span
											slot="label">模板{{option.id==1?'一':option.id==2?'二':option.id==3?'三':'四'}}：</span>
									</el-form-item>
									<el-form-item label="模板名称：">
										<el-input v-model="option.title" class="inputWidth"></el-input>
									</el-form-item>
									<el-form-item label="短信内容：">
										<el-input @input="handleInput1($event,index)" v-model="option.content"
											class="inputWidth" type="textarea" :rows="5"></el-input>
									</el-form-item>
									<el-form-item label="">
										<div class="greenColor">{{option.num}}个字符（不含签名），按{{option.one_price}}条计费</div>
									</el-form-item>
								</div>
							</div>
						</div>
						<div style="float:right;" class="mt-20">
							<el-form-item>
								<el-button type="primary" @click="saveSix" v-if="permission.includes('sys:marketing_check_ads:mb_submit')">确认提交</el-button>
							</el-form-item>
						</div>
					</el-form>
				</el-card>
			</el-tab-pane>
			<el-dialog v-dialogDrag title="查看发送手机号" :visible.sync="dialogVisiblePhone" custom-class="ele-dialog-form" width="600px"
				:lock-scroll="false" :destroy-on-close="true">
				<el-card shadow="never" style="min-height:500px">
					<el-input v-for="(item,index) in allPhone" v-model="allPhone[index]" :key="index"
						class="phoneinputWidth" disabled></el-input>
				</el-card>
			</el-dialog>
		</el-tabs>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "SysUser",
		data() {
			return {
				table: {
					url: '/voice/voiceRecharge',
					where: {}
				}, // 表格配置
				table1: {
					url: '/Marketing/check_log',
					where: {

					}
				}, // 审核列表
				table2: {
					url: '/Marketing/send_log',
					where: {}
				}, // 营销短信推送记录
				table3: {
					url: '/Marketing/notify_log',
					where: {}
				}, // 回执记录
				activeName: 'first',
				provArr: [],
				cityArr: [],
				districtArr: [],
				editFive: [],
				editSix: [],
				allPhone: [],
				disabled: false,
				dialogVisiblePlans: false,
				dialogVisibleSending: false,
				dialogVisibleTemplates: false,
				dialogVisiblePhone: false,
				choose: [], // 表格选中数据
				editFirst: {},
				editForm: {
					sendContent: '',
					sendNum: '0',
					sendPrice: '1',
					pid: '',
					cid: '',
					aid: '',
					type: 2
				},
				checkForm: {},
				imageUrl: false,
				headImg: '',
				old_price: '',
				voiceRecharge: {},
				id: '',
				price: '',
				handleInputClick1Index: 0
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
			this.$http.get('/Marketing/sms_index').then(res => {
				this.editFirst = res.data
			})

			// this.$http.get('/voice/voiceModel').then(res=>{
			//   let data=JSON.parse(res.data)
			//   this.editFirst=data.data
			// })
			// this.$http.get('/voice/voiceRecharge').then(res=>{
			//
			//   this.voiceRecharge=res.data
			//   //console.log(this.voiceRecharge)
			// })

		},

		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {
			handleDistrictArr() {
				let editForm = {
					type: this.editForm.type,
					aid: this.editForm.aid
				}
				this.$http.post('/Marketing/sms_index', editForm).then(res => {
					this.editFirst = res.data
				})
			},
			hangleTypt() {
				let editForm = {
					type: this.editForm.type,
					aid: this.editForm.aid
				}
				this.$http.post('/Marketing/sms_index', editForm).then(res => {
					this.editFirst = res.data

				})
			},
			handleSending() {
				this.dialogVisibleTemplates = true
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".templates")
				});
				this.$http.get('/Marketing/template_list').then(res => {
					this.editFive = JSON.parse(res.data)
					loading.close()
				})
			},
			form() {},
			exportExcel() {},
			handleSend(content) {
				this.$confirm('确定要使用该套模板吗?', '提示', {
					type: 'warning'
				}).then(() => {
					this.dialogVisibleTemplates = false
					this.editForm.sendContent = content
					this.editForm.sendNum = content.length
					if (this.editForm.sendNum >= 70) {
						this.editForm.sendPrice = 2
					} else {
						this.editForm.sendPrice = 1
					}
					this.disabled = true
				})
			},
			handleInput(e) {
				this.editForm.sendNum = e.length
				if (this.editForm.sendNum >= 70) {
					this.editForm.sendPrice = 2
				} else {
					this.editForm.sendPrice = 1
				}
			},
			handleInput1(e, index) {
				this.editSix[index].num = e.length
				if (this.editSix[index].num >= 70) {
					this.editSix[index].one_price = 2
				} else {
					this.editSix[index].one_price = 1
				}
			},
			handleChange(id, price) {
				let that = this
				this.id = id
				this.price = price
				//console.log(this.id, this.price)
				this.editFive.forEach(function(item) {
					if (item.id == id) {
						item.greenborder = true
						that.old_price = item.price
					} else {
						item.greenborder = false
					}
				})

			},
			// 购买短信套餐
			purchaseSave() {
				const loading = this.$loading({ lock: true,target: document.querySelector(".plans")});
				this.$http.post('/Marketing/buy_sms', {
					sms_id: this.id,
					amount: this.price
				}).then(res => {
					loading.close()
					if (res.data.code === 1) {
						// const loading = this.$loading({ lock: true,target: document.querySelector(".d-flexspabet")});
						// this.dialogVisiblePlans = false;
						this.$http.get('/Marketing/sms_index').then(res => {
							this.editFirst = res.data
						})
						this.$message({
							type: 'success',
							message: '购买成功'
						});
						loading.close()
					} else {
						this.$message.error({
							type: 'error',
							message: '购买失败'
						});
					}
				})
			},
			handlePurchase() {
				this.dialogVisiblePlans = true
				let that = this
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".plans")
				});
				this.$http.get('/Marketing/sms_set_meal', ).then(res => {
					let data = JSON.parse(res.data)
					loading.close()
					data.forEach(function(item) {
						if (item.id == 1) {
							item.greenborder = true
							that.old_price = item.price
						} else {
							item.greenborder = false
						}

					})
					this.editFive = data
					this.id = this.editFive[0].id
					this.price = this.editFive[0].price
				})
			},
			check_success(row) {
				this.$confirm('确定通过营销短信吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/marketing/check_ads', {
						id: row.id,
						status: 1
					}).then(res => {
						loading.close();
						let data = res.data
						if (data.code === 0) {
							this.$message({
								type: 'success',
								message: '通过成功'
							});
							this.$refs.table1.reload();
						} else {
							this.$message.error(data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			check_error(row) {

				//console.log(row)
				this.$confirm('确定驳回营销短信吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/marketing/check_ads', {
						id: row.id,
						status: 2
					}).then(res => {
						loading.close();
						let data = res.data
						if (data.code === 0) {
							this.$message({
								type: 'success',
								message: '驳回成功'
							});
							this.$refs.table1.reload();
						} else {
							this.$message.error(data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cid = ''
					this.editForm.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aid = ''
				})
				}else{
					this.editForm.pid = ''
					this.editForm.aid = ''
				}
			},
			handlecheck() {
				this.dialogVisiblePhone = true
			},
			/**
			 *选择省
			 **/
			handleChangeProv2(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table2.where.cid = ''
					this.table2.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity2(e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table2.where.aid = ''
				})
			},
			viewFive(row) {
				this.dialogVisiblePhone = true
				this.allPhone = row.all_phone.split(',')
			},
			saveFive() {
				const loading = this.$loading({
					lock: true
				});
				let editFive = this.editFive
				this.$http.post('/Marketing/set_meal_edit', editFive).then(res => {
					loading.close();
					if (res.data.code == 0) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						})
					} else {
						this.$message.error(res.data.msg)
					}
				}).catch(e => {
					this.$message.error(e.message);

				})
			},

			saveSix() {
				const loading = this.$loading({
					lock: true
				});
				let editSix = this.editSix
				this.$http.post('/Marketing/template_edit', editSix).then(res => {
					loading.close();
					if (res.data.code == 0) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						})
					} else {
						this.$message.error(res.data.msg)
					}
				}).catch(e => {
					this.$message.error(e.message);

				})
			},

			//短信推送提交
			save() {
				// const loading = this.$loading({lock: true});
				let param = {
					content: this.editForm.sendContent,
					pid: this.editForm.pid,
					cid: this.editForm.cid,
					aid: this.editForm.aid,
					prove: this.editForm.sendPrice,
					send_time: this.editForm.send_time,
					tiao: this.editForm.sendNum,
					type: this.editForm.type
				}
				this.$confirm('确定使用余额支付?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/marketing/sms_submit_push', param).then(res => {
						loading.close();
						let data = res.data
						if (data.code === 0) {
							this.$message({
								message: data.msg,
								type: 'success'
							})
              this.$refs.table1.reload();
						} else {
							this.$message.error(data.msg)
						}
					}).catch(e => {
						this.$message.error(e.message);

					})

				})

			},
			handleClick(e) {
				if (e.name == 'five') {
					const loading = this.$loading({ lock: true});
					this.$http.get('/Marketing/sms_set_meal', ).then(res => {
						loading.close()
						this.editFive = JSON.parse(res.data)
					})
				} else if (e.name == 'six') {
					const loading = this.$loading({
						lock: true
					});
					this.$http.get('/Marketing/template_list', ).then(res => {
						loading.close()
						let data = JSON.parse(res.data)
						this.editSix = data
					})
				}
			},
			switch_start(row) {
				if (row.type == 1) {
					this.$confirm('确定关闭语音通话吗?', '提示', {type: 'warning'}).then(() => {
						const loading = this.$loading({lock: true});
						this.$http.post('/voice/openingType', {id: row.id,type: 2}).then(res => {
							loading.close();
							let data = JSON.parse(res.data)
							if (data.code === 1) {

								this.$message({
									type: 'success',
									message: '关闭成功'
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				} else {
					this.$confirm('确定启用语音通话吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/voice/openingType', {
							id: row.id,
							type: 1
						}).then(res => {
							let data = JSON.parse(res.data)
							loading.close();
							if (data.code === 1) {
								this.$message({
									type: 'success',
									message: '启用成功'
								});
								this.$refs.table.reload();
								this.switchStart = '关闭'
							} else {
								this.$message.error(data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			}
		}
	}
</script>

<style scoped>
	.phoneinputWidth {
		width: 166px;
		margin-right: 10px;
		margin-bottom: 20px
	}

	.el-form-item label:after {
		content: "";
		display: inline-block;
		width: 100%;
	}

	.el-form-item__label {
		text-align: left;
		height: 50px;
		margin-left: 40px;
	}

	.el-form-item.is-required .el-form-item__label:before {
		content: none !important;
	}

	.lifive {
		width: 100%;
		height: 20px;
		background: #2CB394;
		border-top-left-radius: 6% 6%;
		border-bottom-right-radius: 20% 100%;
		margin-left: -10px;
		color: #f9f9f9;
		padding-top: 3px;
		padding-left: 10px;
	}

	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.ele-body {
		padding: 15px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	/deep/.el-tabs__item {
		padding: 0 20px !important;
		font-size: 15px;
	}

	/deep/.el-tabs__nav-scroll {
		background: white !important;
		padding-top: 10px;
	}

	.boxTitle {
		padding: 8px
	}

	.orderImgBox {
		margin-left: 10px;
		height: 80px;
	}

	.orderImgBox span {
		font-size: 36px;
	}

	.driverForm .area /deep/.el-form-item__content {
		margin-left: 0 !important
	}

	.user-info-tabs>>>.el-tabs__nav-wrap {
		padding-left: 20px;
	}

	.w-40 {
		width: 49%;
	}

	.innerBox {
		width: 100%;
		margin-left: 100px;
		padding-top: 20px;
	}

	.greenborder {
		border: 1px solid #33cc99 !important
	}

	.innerItem {
		width: 30%;
		margin-right: 100px;
	}

	.contentLine {
		line-height: 25px;
		margin-bottom: 40px
	}

	.smsInner {
		border-bottom: 1px solid rgb(240, 240, 240);
		padding-bottom: 10px;
	}

	.innerBoxTo {
		width: 20%;
		margin-left: 100px;
		padding-top: 20px;
	}

	.innerTitle {
		font-size: 16px;
		margin-bottom: 20px;
		margin-left: 13px;
	}

	.inputWidth {
		width: 100%;
	}

	.colorOrange {
		color: #ff892e
	}

	.plansBox {
		width: 100%;
		border: 1px solid rgb(240, 240, 240);
		margin: auto
	}

	.plansItem {
		display: flex;
		justify-content: space-around;
		text-align: center;
		margin: 0 30px;
	}

	.plansInner {
		padding: 0 10px;
		border: 1px solid rgb(240, 240, 240);
		margin: 20px 0;
		width: 18%
	}

	.plansInner1 {
		margin: 20px 0;
		width: 18%
	}

	.plansCon {
		padding: 0 10px 10px;
		border: 1px solid rgb(240, 240, 240);
		width: 100%;
		margin-bottom: 20px;
		height: 310px
	}

	.plansTitle {
		color: #33cc99;
		padding: 30px 0 20px 0;
	}

	.plansMoney {
		color: #ff892e;
		padding: 0 20px 20px;
		border-bottom: 1px solid rgb(240, 240, 240);
		width: 40%;
		margin: auto;
	}

	.plansCluse {
		padding: 20px;
	}

	.plansNum {
		padding-bottom: 40px;
	}

	.plansMiaoshu {
		padding: 60px 0 0;
		color: #33cc99
	}

	.plansDeduct {
		padding-left: 50px;
		padding-bottom: 20px
	}

	.plansDeduct span {
		color: #ff892e;
		padding-left: 30px
	}

	.plansContent {
		padding: 30px 0;
		height: 120px
	}
	/deep/.el-dialog{
		margin-top: 80px !important;
	}
	.closeBtn{
		display: inline-block;height: 36px;width: 90px;text-align: center;line-height: 36px;margin-left: 10px;
		background: red;border-radius: 4px;font-size: 14px;color: white;text-decoration: none;
	}
	.el-textarea__inner{
		padding-top: 0;
	}
    .boxType{
        box-sizing: border-box;
    }
</style>
